<template>
  <div class="page question-page">
    <QHeader />

    <img src="../../assets/images/q2.png" class="content" />

    <div class="ask">
      <img src="../../assets/images/earphone.png" class="ask-bg" />
      <div class="ask-no">
        <h4>第二题</h4>
        <p>QUESTION SECOND</p>
      </div>
      <div class="ask-line"></div>
      <div class="ask-content">
        <p>您听到非常愉悦的声音，</p>
        <p>您认为应该是？</p>
      </div>
    </div>

    <div class="items">
      <div
        class="item"
        :class="{ active: key === choice }"
        v-for="(item, key) in items"
        :key="key"
        @click="select(key)"
      >
        {{ item }}
      </div>
    </div>

    <div class="action">
      <button class="btn" @click="back">上一题</button>
      <button class="btn next" @click="next">下一题</button>
    </div>
  </div>
</template>
<script>
import QHeader from "../../components/QHeader";
import { mapState } from "vuex";

export default {
  name: "Step2",

  components: { QHeader },

  computed: {
    ...mapState({
      record: (state) => state.detail.record,
    }),
  },

  watch: {
    "$store.state.detail.record"(record) {
      this.choice = record[1];
    },
  },

  data() {
    return {
      choice: null,

      items: [
        "A. 劲风吹过矮矮的灌木丛，苍茫空阔",
        "B. 雨滴落在水面上，滴滴答答，涟漪一圈圈泛开",
        "C. 森林深处，树叶摇曳，沙沙作响",
      ],
    };
  },

  mounted() {
    this.choice = this.record[1];
    this.$store.dispatch("share/config");
  },

  methods: {
    select(key) {
      this.choice = key;
    },

    back() {
      this.$router.back();
    },

    next() {
      if (![0, 1, 2].includes(this.choice)) {
        this.$layer.msg("请点击您的选择");
        return;
      }

      this.$store.commit("record/choice", [1, this.choice]);
      this.$router.push("/step3");
    },
  },
};
</script>
<style scoped lang="less">
img {
  display: block;

  &.content {
    width: 100%;
  }
}

.ask {
  position: relative;

  .ask-bg {
    width: 80px;
    position: absolute;
    top: -15px;
    right: 0;
  }
}
</style>